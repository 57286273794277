import { useContext } from "react";
import { GlobalContext } from "../../../../../GlobalContext";
import route_post_universa from "../../../../../../routes/global/route_post_universal"

const BtnRegistration = ({
  setRegistration,
  setErrorMSG,
  name,
  surname,
  addressing_surname,
  gender,
  phone,
  email,
  password,
  password2,
  rankReg,
  namePosition,
  passwordParent,
  selectedColor,
}) => {
  const { urlServer, setError /* admin_email*/ } = useContext(GlobalContext);

  // ------------------------------------
  const btnRegistration = async (e) => {
    e.preventDefault();
    console.log(name, surname, gender, phone, email, password, password2, rankReg, namePosition, passwordParent, selectedColor)
    if (!name || !surname || (gender !== "0" && gender !== "1") || !phone || !email || !password || !password2 || rankReg < 0 || !namePosition || !passwordParent || !selectedColor) {
      setErrorMSG("Vyplňte všechny položky");
    } else if (password !== password2) {
        setErrorMSG("Hesla se neshodují");
    } else {
      // update inputs values
      // clear whiteSpace
      const normalizeInput = (input) => {
        return input.trim().replace(/\s+/g, " ");
      };
      const normalizedName = normalizeInput(name);
      const normalizedSurname = normalizeInput(surname);
      let normalizedAddressing_surname = undefined;
      if(addressing_surname) {
        normalizedAddressing_surname = normalizeInput(addressing_surname)
      }
      const normalizedNamePosition = normalizeInput(namePosition)
      // email remove white space
      const removeEmailWhitespace = (input) => {
        return input.replace(/\s+/g, "").toLowerCase();
      };
      const normalizedEmail = removeEmailWhitespace(email);

      //Regex inputs values
      // Regex name **** ****
      // const regexName = /^\S{2,}\s\S{2,}$/;
      const regexName = /^\S{2,}(?:\s\S{2,})?$/;
      const isValidName = regexName.test(normalizedName);
      const isValidSurname = regexName.test(normalizedSurname);
      if (!isValidName || !isValidSurname) {
        return setErrorMSG("Jméno nebo Příjmení je ve špatném formátu");
      }
      // Regex email
      const regexEmail = /^[^\s@]+@[^\s@]+[^\s@]+$/;
      const isValidEmail = regexEmail.test(normalizedEmail);
      if (!isValidEmail) {
        return setErrorMSG("Email je ve špatném formátu");
      }

      const normalized_Password = normalizeInput(password);
      const normalized_PasswordParent = normalizeInput(passwordParent);

      // -----------------------------------------
      try {
      const response = await route_post_universa(
        urlServer,
        "/registerAdmin",
        "name",
        normalizedName,
        "surname",
        normalizedSurname,
        "addressing_surname",
        normalizedAddressing_surname,
        "gender",
        gender,
        "phone",
        phone,
        "newEmail",
        normalizedEmail,
        "password",
        normalized_Password,
        "rank_new",
        rankReg,
        "position_name",
        normalizedNamePosition,
        "parentUserPassowrd",
        normalized_PasswordParent,
        "color",
        selectedColor
      )

      if(response?.msg === "Nový admin vytvořen"){
        setError(response.msg)
        setRegistration(false);
        // setTimeout(() => {
        //     setRegistration(false); // Vyprázdnění konzole po 5 sekundách
        // }, 2000); // 2000 milisekund = 5 sekund
      } else {
        setErrorMSG(response.msg)
      }
    } catch (err) {
      // Zobrazení chybové zprávy uživateli
      setErrorMSG(err.message || "Nastala chyba serveru. Kontaktujte podporu.");
    }
    }
  };

  return (
    <div>
      <button onClick={btnRegistration}>Registrovat</button>
    </div>
  );
};

export default BtnRegistration;
