// export const f_updateConsultationStatus = (setFetch_result, id, newStatus) => {
//   setFetch_result((prevResult) => {
//       console.log("Previous Result:", prevResult);

//       // Kontrola, zda `fetch_data` existuje a je polem
//       if (Array.isArray(prevResult.fetch_data)) {
//           const updatedData = prevResult.fetch_data.map((item) => {
//               if (item._id === id) {
//                   return { ...item, consultation_complete: newStatus };
//               }
//               return item;
//           });
//           return { ...prevResult, fetch_data: updatedData };
//       } else if (Array.isArray(prevResult)) {
//           // Pokud je `prevResult` polem bez `fetch_data`, zpracujeme ho jako takové
//           const updatedData = prevResult.map((item) => {
//               if (item._id === id) {
//                   return { ...item, consultation_complete: newStatus };
//               }
//               return item;
//           });
//           return updatedData; // Vrací aktualizované pole
//       } else {
//           console.error("Unsupported structure in `fetch_result`");
//           return prevResult; // Vrací původní strukturu, pokud není rozpoznána
//       }
//   });
// };

export const f_updateConsultationStatus = (setFetch_result, id, updates) => {
    setFetch_result((prevResult) => {
      console.log("Previous Result:", prevResult);
  
      // Kontrola, zda `fetch_data` existuje a je polem
      if (Array.isArray(prevResult.fetch_data)) {
        const updatedData = prevResult.fetch_data.map((item) => {
          if (item._id === id) {
            return { ...item, ...updates }; // Sloučí původní objekt s aktualizacemi
          }
          return item;
        });
        return { ...prevResult, fetch_data: updatedData };
      } else if (Array.isArray(prevResult)) {
        // Pokud je `prevResult` polem bez `fetch_data`, zpracujeme ho jako takové
        const updatedData = prevResult.map((item) => {
          if (item._id === id) {
            return { ...item, ...updates }; // Sloučí původní objekt s aktualizacemi
          }
          return item;
        });
        return updatedData; // Vrací aktualizované pole
      } else {
        console.error("Unsupported structure in `fetch_result`");
        return prevResult; // Vrací původní strukturu, pokud není rozpoznána
      }
    });
  };