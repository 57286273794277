import { useState } from "react";
import "./OneCompany.scss";
import BtnClose from "../../../../elements/btn/BtnClose/BtnClose";
import { f_date_only } from "../../../../../utils/date/f_date_only";
import { f_date_to_cz } from "../../../../../utils/date/f_date_to_cz";
import CompanyCEODetails from "./CompanyCEO_Details/CompanyCEO_Details";

const OneCompany = ({ fetch_data, admin_create_data, setClose }) => {
  let {
    /*_id, */ company,
    company_URL,
    date_create,
    coins,
    main_processor,
    CEO,
  } = fetch_data;

  const { name, surname, color } = admin_create_data;

  const [CEO_show, setCEO_show] = useState(false);
  const [main_processor_show, setMain_processor_show] = useState(false);

  // ----------------------------
  // Replace CEO + mainprocesor object values
  const reorderObject= (obj, keysOrder) => {
    const reordered = {};
    keysOrder.forEach((key) => {
      if (key in obj) {
        reordered[key] = obj[key];
      }
    });
    return reordered;
  }

// Definujte pořadí klíčů
const mainProcessorOrder = [
  "main_processor_Name",
  "main_processor_Surname",
  "main_processor_Email",
  "main_processor_Phone",
  "main_processor_Code",
  "main_processor_Code_active",
];

const CEOOrder = [
  "CEO_Name",
  "CEO_Surname",
  "CEO_Email",
  "CEO_Phone",
  "CEO_Code",
  "CEO_Code_active",
];

// Uspořádejte objekty podle pořadí
const reorderedMainProcessor = reorderObject(main_processor, mainProcessorOrder);
const reorderedCEO = reorderObject(CEO, CEOOrder);

// -----------------------------------



  return (
    <div className="OneCompany">
      <div className="popUp_full_window">
        {/* BTN Close */}
        <BtnClose setClose={setClose} />

        {/* Title */}
        <div className="popUp_full_title">
          <h2>Společnost</h2>
        </div>

        {/* Main */}
        <div className="popUp_full_main">
          <section className="company_data">
            <div className="company_Name_box">
              <h3 className="company_Name">{company}</h3>
            </div>
{/* Comapny Details */}
            <div className="company_data_details1">
            <div className="item" style={{borderColor: color }}>
                <p className="detail_title">Založil uživatel</p>
                <p className="detail_text">
                  {name} {surname}
                </p>
              </div>
              <div className="item">
                <p className="detail_title">Datum vytvoření</p>
                <p className="detail_text" title={f_date_to_cz(date_create)}>
                  {f_date_only(date_create)}
                </p>
              </div>
              <div className="item">
                <p className="detail_title">URL</p>
                <p className="detail_text">{company_URL}</p>
              </div>
              <div className="item">
                <p className="detail_title">Zbývající počet coinů</p>
                <p className="detail_text">{coins}</p>
              </div>
            </div>
{/* CEO + main_processor */}
            <div className="company_data_CEO">
              {
                // CEO
                CEO.CEO_Name && <CompanyCEODetails 
                              text="CEO"
                              data={reorderedCEO}
                              value={CEO_show}
                              set={setCEO_show}
                            />
              }
              {
                // main processor
                main_processor.main_processor_Name && <CompanyCEODetails  
                                        text="kontaktní osobu"
                                        data={reorderedMainProcessor}
                                        value={main_processor_show}
                                        set={setMain_processor_show}
                                      />
              }
              
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default OneCompany;
