import { useState, useContext } from "react";
import { GlobalContext } from "../../../../GlobalContext";
import "./Registration_window.scss";
import InputContainer from "../../../../../components/elements/inputs/InputContainer/InputContainer";
import InputPassword from "../../../../../components/elements/inputs/InputPassword/InputPassword";
import SelectBasic from "../../../../../components/elements/selects/SelectBasic/SelectBasic";
import BtnRegistration from "./BtnRegistration/BtnRegistration";
import BtnClose from "../../../../../components/elements/btn/BtnClose/BtnClose";
import InputColor from "../../../../../components/elements/inputs/InputColor/InputColor";
import rank_data from "../../../../../data/user-data/rank_data";
import RadioGende from "../../../../../components/elements/radio/Radio_Gender/Radio_Gender";


const Registration_window = ({ setRegistration }) => {
  const { admin_rank } = useContext(GlobalContext);

  const [gender, setGender] = useState("");
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [addressing_surname, setAddressing_surname] = useState("");
  const [email, setEmail] = useState("");

  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [rankReg, setRankReg] = useState("");
  const [namePosition, setNamePosition] = useState("");
  const [passwordParent, setPasswordParrent] = useState("");
  // color
  const [showColor, setShowColor] = useState(false);
  const [selectedColor, setSelectedColor] = useState("");
  // error
  const [errorMSG, setErrorMSG] = useState("");

  // -----------------------------------------
  //f
  // - filtr rank select data
  const filteredRanks = rank_data.filter(rank => rank.value > admin_rank);

  // -----------------------------------------

  return (
    <div className="Registration_window">
      <section className="form">
        <BtnClose setClose={setRegistration} />
        <div className="title">
          <h1>Registrace</h1>
        </div>

        <div className="input_box">
          <form>
            <h2>Nový uživatel</h2>
            <InputContainer
              type="text"
              label="Jméno"
              id="name"
              value={name}
              set={setName}
              regexSetting="name"
            />
            <InputContainer
              type="text"
              label="Příjmení"
              id="surname"
              value={surname}
              set={setSurname}
              regexSetting="name"
            />
            <InputContainer
              type="text"
              label="Přezdívka (nepovinné)"
              id="addressing_surname"
              value={addressing_surname}
              set={setAddressing_surname}
              regexSetting="name"
            />
            <InputContainer
              type="email"
              label="E-mail"
              id="email"
              value={email}
              set={setEmail}
              regexSetting="email"
              autoComplete="off"
            />

            {/* Phone */}
            <InputContainer
              type="tel"
              label="Telefon (včetně předvolby)"
              id="phone"
              value={phone}
              set={setPhone}
              regexSetting="phone"
            />

            {/* Gender */}
            <RadioGende gender={gender} setGender={setGender} />

            {/* Password */}
            <InputPassword
              label="Heslo"
              id="password"
              value={password}
              set={setPassword}
              regexSetting="password"
              autoComplete="new-password"
            />
            <InputPassword
              label="Heslo znovu"
              id="password2"
              value={password2}
              set={setPassword2}
              regexSetting="password"
              autoComplete="new-password"
            />
          </form>
          
          {/* Parant */}
          <form>  
            <div className="parent"></div>
            <h2>Nadřazený uživatel</h2>

            <SelectBasic
              object={filteredRanks}
              onChange={setRankReg}
              placeholder="Vyberte pozici nového uživatele"
            />
            <InputContainer
              type="tel"
              label="Jméno pozice"
              id="namePosition"
              value={namePosition}
              set={setNamePosition}
              regexSetting="tag"
            />
            <div
              className="button_box btn_colorSelect"
              style={{ backgroundColor: selectedColor }}
            >
              <button onClick={(e) => { e.preventDefault(); setShowColor(true); }}>Vybrat barvu</button>
            </div>

            <InputContainer
              type="password"
              label="Heslo nadřazeného uživatele"
              id="passwordParent"
              value={passwordParent}
              set={setPasswordParrent}
              regexSetting="email"
              autoComplete="off"
            />

            {showColor && (
              <InputColor
                selectedColor={selectedColor}
                setSelectedColor={setSelectedColor}
                setShowColor={setShowColor}
              />
            )}
          </form>
        </div>

        <div
          className="error_box"
          style={{ color: errorMSG === "Nový admin vytvořen" ? "green" : "red" }}
        >
          <p>{errorMSG}</p>
        </div>

        <div className="button_box">
          <BtnRegistration
            setErrorMSG={setErrorMSG}
            name={name}
            surname={surname}
            addressing_surname={addressing_surname}
            gender={gender}
            phone={phone}
            email={email}
            password={password}
            password2={password2}
            rankReg={rankReg}
            namePosition={namePosition}
            passwordParent={passwordParent}
            selectedColor={selectedColor}
            setRegistration={setRegistration}
          />
        </div>
      </section>
    </div>
  );
};

export default Registration_window;