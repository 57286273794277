import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../global/GlobalContext";
import route_post_universal from "../../routes/global/route_post_universal";

export const useOneColorSet = ( colorSet_ID ) => {
  const {urlServer } = useContext(GlobalContext);
  const [fetch_data, setFetch_data] = useState({});

  useEffect(() => {
    const fetchColorSet = async () => {
      if (!colorSet_ID) return; // Pokud není colorSet_ID, funkci nevoláme

      try {
        const result = await route_post_universal(
          urlServer,
          "/findOneColorSet",
          "colorSet_ID",
          colorSet_ID
        );

        setFetch_data(result); // Uložení výsledků
        console.log(result.msg)
      } catch (error) {
        console.error("Error fetching color set:", error);
      }
    };

    fetchColorSet(); // Zavolání asynchronní funkce
    

  }, [colorSet_ID, urlServer]);

  return [fetch_data, setFetch_data]; // Vrátíme data, pokud chceme zpracovávat mimo hook
};