import { useState } from "react";
import "./BtnRegistration.scss";
import RegistrationWindow from "./Registration_window/Registration_window";

const BtnRegistration = () => {
    const [registration, setRegistration] = useState(false)


  return (
    <div className="Registration">
      <button className="registration" onClick={() => setRegistration(true)}>Registrace</button>

      {
        registration && <RegistrationWindow setRegistration={setRegistration} />
      }
    </div>
  );
};

export default BtnRegistration;
