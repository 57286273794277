import { useEffect, useContext, useState } from "react";
import { GlobalContext } from "../../../../../../../../global/GlobalContext";
import route_post_universal from "../../../../../../../../routes/global/route_post_universal";
import SelectMore from "../../../../../../../elements/selects/SelectMore/SelectMore";

const SelectWordSet= ({ wordSet_select, setWordSet_select }) => {
  const { urlServer } = useContext(GlobalContext);

  const [options, setOptions] = useState([]);
  console.log(options)

  useEffect(() => {
    const wordSets = async () => {
      const response = await route_post_universal(
        urlServer,
        "/findWordSets"
      );
      if (response.msg === "Word sety vypsané") {
        setOptions(response.fetch_data);
      }
    };

    if (options.length === 0) {
      wordSets();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {options.length > 0 && (
          <SelectMore
            object={options}
            onChange={setWordSet_select}
            placeholder="Vybere Slovní modul"
            defaultOption={wordSet_select}
          />
      )}
    </>
  );
};

export default SelectWordSet;
