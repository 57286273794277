export const f_date_only = (isoDate) => {
    const date = new Date(isoDate);
  
    // Získání jednotlivých částí data
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Měsíce jsou 0-indexované
    const year = date.getFullYear();
    // const hours = String(date.getHours()).padStart(2, "0");
    // const minutes = String(date.getMinutes()).padStart(2, "0");
  
    // Sestavení data ve formátu dd.mm.rrrr hh:mm bez mezer
    return `${day}.${month}.${year}`;
  };
  