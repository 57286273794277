// import React, { useEffect, useState, useContext, useRef } from 'react';
// import { GlobalContext } from '../../GlobalContext';
// import './ErrorMsg.scss';

// const ErrorMsg = () => {
//   const { error, setError, error_position, setError_position } = useContext(GlobalContext);
//   const [currentError, setCurrentError] = useState('');
//   const [isSticky, setIsSticky] = useState(false);
//   const [isVisible, setIsVisible] = useState(false);
//   const hideTimeoutRef = useRef(null);

//   const startHideTimeout = () => {
//     hideTimeoutRef.current = setTimeout(() => {
//       setIsVisible(false);
//       setCurrentError('');
//       hideTimeoutRef.current = null;
//     }, 5000);
//   };

//   const clearHideTimeout = () => {
//     if (hideTimeoutRef.current) {
//       clearTimeout(hideTimeoutRef.current);
//       hideTimeoutRef.current = null;
//     }
//   };

//   useEffect(() => {
//     const header = document.querySelector('header');

//     const observer = new IntersectionObserver(
//       ([entry]) => {
//         setIsSticky(!entry.isIntersecting);
//       },
//       { threshold: 1.0 }
//     );

//     if (header) {
//       observer.observe(header);
//     }

//     return () => observer.disconnect();
//   }, []);

//   useEffect(() => {
//     if (error) {
//       clearHideTimeout();
//       setCurrentError(error);
//       setIsVisible(true);
//       setError('');
//       startHideTimeout();
//     }
//   }, [error, setError]);

//   useEffect(() => {
//     return () => clearHideTimeout();
//   }, []);

//   return (
//     <div
//       style={{}}
//       className={`ErrorMsg ${isSticky ? 'sticky' : ''} ${isVisible ? 'visible' : 'hidden'}`}
//       onMouseEnter={clearHideTimeout}
//       onMouseLeave={startHideTimeout}
//     >
//       {currentError}
//     </div>
//   );
// };

// export default ErrorMsg;


import React, { useState, useContext, useRef, useEffect } from 'react';
import { GlobalContext } from '../../GlobalContext';
import './ErrorMsg.scss';

const ErrorMsg = () => {
  const { error, setError } = useContext(GlobalContext);
  const [currentError, setCurrentError] = useState('');
  const [isVisible, setIsVisible] = useState(false);
  const hideTimeoutRef = useRef(null);

  const startHideTimeout = () => {
    hideTimeoutRef.current = setTimeout(() => {
      setIsVisible(false);
      setCurrentError('');
      hideTimeoutRef.current = null;
    }, 5000);
  };

  const clearHideTimeout = () => {
    if (hideTimeoutRef.current) {
      clearTimeout(hideTimeoutRef.current);
      hideTimeoutRef.current = null;
    }
  };

  // Nastavení chyby při změně `error`
  useEffect(() => {
    if (error) {
      clearHideTimeout();
      setCurrentError(error);
      setIsVisible(true);
      setError(''); // Vyčistíme error pro budoucí použití
      startHideTimeout();
    }
  }, [error, setError]);

  return (
    isVisible && (
      <div
        className={`ErrorMsg ${isVisible ? 'visible' : 'hidden'}`}
        onMouseEnter={clearHideTimeout}
        onMouseLeave={startHideTimeout}
      >
        {currentError}
      </div>
    )
  );
};

export default ErrorMsg;

