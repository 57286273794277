import { useState } from "react";
import "../../../completeComponents/UserData_listFrom_last_result/UserData_listFrom_last_result.scss";
import "./Companies_Result.scss";
import f_initials from "../../../../utils/nameAndEmail/f_initials";
import { useOneShow_Global } from "../../../../hooks/useOneShow_Global/useOneShow_Global";
import OneCompany from "./OneCompany/OneCompany";

const CompaniesResult = ({ fetch_result }) => {
    const [showOneCompany, setShowOneCompany] = useState(false)

 // -----------
      
      // Custom hook
      // newRespondent
      const [fetch_data_one] = useOneShow_Global("/find_oneCompany", "oneCompany_ID", showOneCompany?.oneColorSet_info?._id)
  
      console.log(showOneCompany)
      console.log(fetch_data_one)
      // -----------
      console.log(fetch_data_one?.fetch_data?._id, showOneCompany?.oneColorSet_info?._id)

  return (
    <div className="UserData_listFrom_last_result">
        {/* One Company */}
        {
            fetch_data_one?.fetch_data?._id && showOneCompany?.oneColorSet_info?._id 
              && <OneCompany 
                    fetch_data={fetch_data_one?.fetch_data} 
                    admin_create_data={showOneCompany?.oneColorSet_info?.admin}
                    setClose={setShowOneCompany} 
                  />
        }

      {fetch_result.fetch_data && (
        <div className="colorSets">
          <div className="titles">
            <p>Založil</p>
            <p>Jméno společnosti</p>
            <p>Počet coinů</p>
            <p>Počet tagů</p>
          </div>

          {fetch_result.fetch_data.map((oneColorSet_info) => {
            const { _id, company, admin, coins, tagCount } = oneColorSet_info;
            const { name, surname, color} = admin;

            return (
              <div key={_id} className="oneColorSet_info company_info" onClick={() => setShowOneCompany({oneColorSet_info})}>
                <div className="item admin">
                  <div
                    className="admin_box"
                    style={{
                      backgroundColor: color,
                    }}
                  >
                    <p className="admin_name" title={`${admin.name} ${admin.surname}`}>
                      {f_initials(`${name} ${surname}`)}
                    </p>
                  </div>
                </div>
                <div className="item">{company}</div>
                <div className="item">{coins}</div>
                <div className="item">{tagCount}</div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default CompaniesResult;
