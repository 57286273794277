import React, { useState } from 'react';
import BtnShow from '../../../../../elements/btn/BTN_Show/Btn_Show';
import "./CompanyCEO_Details.scss";

const CompanyCEODetails = ({ text, data, value, set }) => {
  const [isVisible, setIsVisible] = useState(value);

  const handleToggle = () => {
    setIsVisible((prev) => !prev);
    set(!isVisible); // Aktualizuje hodnotu v rodiči
  };

  return (
    <div className="CompanyCEO_Details">
      <BtnShow text={text} value={isVisible} set={handleToggle} />

      <div className={`details ${isVisible ? "details_show" : "details_hide"}`}>
        <div className="details_box">
            <div className="item">
                <p className="detail_title">Jméno:</p>
                <p className="text">{Object.values(data)[0]}</p>
            </div>
            <div className="item">
                <p className="detail_title">Přijmení:</p>
                <p className="text">{Object.values(data)[1]}</p>
            </div>
            <div className="item">
                <p className="detail_title">E-mail:</p>
                <p className="text">{Object.values(data)[2]}</p>
            </div>
            <div className="item">
                <p className="detail_title">Telefon:</p>
                <p className="text">{Object.values(data)[3]}</p>
            </div>
            <div className="item">
                <p className="detail_title">Přihlašovací kód:</p>
                <p className="text">{Object.values(data)[4]}</p>
            </div>
            <div className="item">
                <p className="detail_title">Účet aktivován:</p>
                <p className="text">{Object.values(data)[5] === true ? "Ne" : "Ano"}</p>
            </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyCEODetails;
