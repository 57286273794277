import { useState } from "react";
import "../ColorSets/ColorSets.scss";
import useFetch_colroSet_info from "../../../hooks/Pages/UserData/useFetch_colorSet_info";
import TagsResponseListFromLast from "../../../components/pages/UserData/Tags/Tags_response_listFromLast";
// import UserDataListFromLastResult from "../../../components/completeComponents/UserData_listFrom_last_result/UserData_listFrom_last_result";
import UserDataListFromLastPageScroll from "../../../components/completeComponents/UserData_listFrom_last_result/UserData_listFromLast_pageScroll/UserData_listFromLast_pageScroll";
import SearchFullResult from "../../../components/elements/Search_FullResult/Search_FullResult";

const Tags = () => {
    const [fetch_result, setFetch_result] = useState([]);
    // new fetch
    const [search, setSearch] = useState("");
    const [page, setPage] = useState(1);
    const [limitResponse, setLimitRespose] = useState(10);

      // -------------------------------
  //custom hook
  
  useFetch_colroSet_info(
    "/findTags_listFromLast",
    "limit",
    limitResponse, // limit on page
    "page",
    page,
    setFetch_result,
    search
  );

  // ---------------------------------------
  console.log(fetch_result)
  
  return (
    <div className="ColorSets">
      <div className="UserData_main">
        <section className="title">
          <h1>Tagy</h1>
        </section>

        <section className="search">
          <SearchFullResult
            inputType="text"
            inputLabel="Vyhledat jméno tagu"
            inputID="tag_name"
            inputRegex="tag"
            state={search}
            setState={setSearch}
            wordResultKey="tag_name"
            urlFetch="/findTags_listFromLast"
            setFetch_result={setFetch_result}
          />
        </section>

        <section className="result">
            <TagsResponseListFromLast fetch_result={fetch_result} setFetch_tags={setFetch_result} />
        </section>
      </div>

      {/* Page Scroller */}
      <section className="pageScroll">
        {fetch_result.totalPages && (
          <UserDataListFromLastPageScroll
            fetch_result={fetch_result}
            page={page}
            setPage={setPage}
            limitResponse={limitResponse}
            setLimitRespose={setLimitRespose}
          />
        )}
      </section>
    </div>
  )
}

export default Tags