const route_find_add = async (
  wordResultKey,
  wordResultKey2,
  state1,
  state2,
  urlServer,
  urlFetch,
  checkBox,
  wordResultKey3,
  state3,
  wordResultKey4,
  state4,
) => {
  try {
    // Získání CSRF tokenu, pokud je potřeba (pouze pro změnové požadavky)
    let csrfToken;
    const csrfResponse = await fetch(`${urlServer}/csrf-token`, { credentials: 'include' });
    const csrfData = await csrfResponse.json();
    csrfToken = csrfData.csrfToken;
    // -----------------------------------------

    let body = {};

    if (wordResultKey && state1) {
      body = {
        [wordResultKey]: state1,
      };
    }

    // When exist WordResultKey 2 + checkBox
    if (wordResultKey2 && state2) {
      body[wordResultKey2] = state2;
    }

    if (typeof checkBox !== 'undefined') {
      body.checkBox = checkBox;
    }

    if (wordResultKey3 && state3 !== "") {
      body[wordResultKey3] = state3;
    }

    if (wordResultKey4 && state4 !== "") {
      body[wordResultKey4] = state4;
    }

    const isEmptyObject = obj => {
      return Object.keys(obj).length === 0 && obj.constructor === Object;
    };

    const requestOptions = {
      method: "post",
      credentials: 'include',
      headers: {
        Accept: "application/json",
        ...(csrfToken ? { 'X-CSRF-Token': csrfToken } : {}), // Přidání CSRF tokenu, pokud je k dispozici
        "Content-type": "application/json",
      },
      ...(isEmptyObject(body) ? {} : { body: JSON.stringify(body) }),
    };

    const response = await fetch(urlServer + urlFetch, requestOptions);

    const data = await response.json();

    if (!response.ok) {
      return console.error(data);
    }

    return data;
  } catch (err) {
    return console.error(
      `Chyba FrontEnd catch ${err}. Kontaktujte nás. Váš Novitim.`
    );
  }
};

export default route_find_add;