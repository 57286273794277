import Select from "react-select";
import "./UserData_listFromLast_limitResponse.scss"

const UserData_listFromLast_limitResponse = ({
  limitResponse,
  setLimitRespose,
}) => {
  const options = [5, 10, 25, 50, 100].map((value) => ({
    value,
    label: value.toString(),
  }));
  const placeholder = "Počet";

  // Najdeme výchozí možnost na základě `limitResponse`
  const defaultOption = options.find((option) => option.value === limitResponse);

  return (
    <div className="UserData_listFromLast_limitResponse">
      <Select
        options={options}
        onChange={(selectedOption) => setLimitRespose(selectedOption.label)}
        placeholder={placeholder}
        defaultValue={defaultOption}
        menuPlacement="auto"
        maxMenuHeight={200}
        styles={{
          control: (provided) => ({
            ...provided,
            textAlign: "center",
          }),
          option: (provided) => ({
            ...provided,
            textAlign: "center",
            cursor: "pointer",
          }),
        }}
      />
    </div>
  );
};

export default UserData_listFromLast_limitResponse;