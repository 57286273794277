import React from "react";
import NewCompany from "./NewCompany/NewCompany";

const RespondentNew2 = ({ setPhase, setError }) => {
  return (
    <>
      <NewCompany setErrorMsg={setError} setPhase={setPhase} />
    </>
  );
};

export default RespondentNew2;
