import React from "react";

const CheckBox = ({value, set, text, disabled, name}) => {
  return (
    <div
      className="CheckBox"
      style={{
        backgroundColor:
        name === "lock" && disabled
          ? "#d3d3d3" // Šedá barva pro "lock"
          : value
          ? "#a8e6a3" // Světle zelená
          : "#f8a5a5", // Světle červená
        transition: "background-color 0.3s ease", // Plynulý přechod barev
      }}
    >
      <label>
        {value ? text[0] : text[1]}
        <input
          type="checkbox"
          checked={value}
          onChange={() => set(!value)}
          disabled={disabled}
        />
        <span className="checkmark"></span>
      </label>
    </div>
  );
};

export default CheckBox;
