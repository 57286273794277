const route_post_universal = async (
    urlServer,
    urlFetch,
    wordResultKey,
    state1,
    wordResultKey2,
    state2,
    wordResultKey3,
    state3,
    wordResultKey4,
    state4,
    wordResultKey5,
    state5,
    wordResultKey6,
    state6,
    wordResultKey7,
    state7,
    wordResultKey8,
    state8,
    wordResultKey9,
    state9,
    wordResultKey10,
    state10,
    wordResultKey11,
    state11,
    wordResultKey12,
    state12,
    wordResultKey13,
    state13,
    wordResultKey14,
    state14,
    wordResultKey15,
    state15,
    wordResultKey16,
    state16,
    //------- NEXT VALUE GET ONLY HERE BOTTOM !!!!!!!!!
  ) => {
    try {
         // Získání CSRF tokenu, pokud je potřeba (pouze pro změnové požadavky)
      let csrfToken;
        const csrfResponse = await fetch(`${urlServer}/csrf-token`, { credentials: 'include' });
        const csrfData = await csrfResponse.json();
        csrfToken = csrfData.csrfToken;
  // -----------------------------------
      let body = {};
  
      if (wordResultKey && state1) {
        body = {
          [wordResultKey]: state1,
        };
      }
  
      // When exist WordResultKey 2
      if (wordResultKey2 && state2) {
        body[wordResultKey2] = state2;
      }
      if (wordResultKey3 && state3 !== "") {
        body[wordResultKey3] = state3;
      }
      if (wordResultKey4 && state4 !== "") {
        body[wordResultKey4] = state4;
      }
      if (wordResultKey5 && state5 !== "") {
        body[wordResultKey5] = state5;
      }
      if (wordResultKey6 && state6 !== "") {
        body[wordResultKey6] = state6;
      }
      if (wordResultKey7 && state7 !== "") {
        body[wordResultKey7] = state7;
      }
      if (wordResultKey8 && state8 !== "") {
        body[wordResultKey8] = state8;
      }
      if (wordResultKey9 && state9 !== "") {
        body[wordResultKey9] = state9;
      }
      if (wordResultKey10 && state10 !== "") {
        body[wordResultKey10] = state10;
      }
      if (wordResultKey11 && state11 !== "") {
        body[wordResultKey11] = state11;
      }
      if (wordResultKey12 && state12 !== "") {
        body[wordResultKey12] = state12;
      }
      if (wordResultKey13 && state13 !== "") {
        body[wordResultKey13] = state13;
      }
      if (wordResultKey14 && state14 !== "") {
        body[wordResultKey14] = state14;
      }
      if (wordResultKey15 && state15 !== "") {
        body[wordResultKey15] = state15;
      }
      if (wordResultKey16 && state16 !== "") {
        body[wordResultKey16] = state16;
      }
  
      const isEmptyObject = obj => {
        return Object.keys(obj).length === 0 && obj.constructor === Object;
      };
  
      const requestOptions = {
        method: "post",
        credentials: 'include',
        headers: {
          Accept: "application/json",
          ...(csrfToken ? { 'X-CSRF-Token': csrfToken } : {}), // Přidání CSRF tokenu, pokud je k dispozici
          "Content-type": "application/json",
        },
        ...(isEmptyObject(body) ? {} : { body: JSON.stringify(body) }),
      };
  
      const response = await fetch(urlServer + urlFetch, requestOptions);
  
      const data = await response.json();
  
      if (!response.ok) {
        // return console.error(data);
        throw new Error(data.msg || "Neznámá chyba serveru.");
      }
  
      return data;
    } catch (err) {
      throw err;
      // return console.error(
      //   `Chyba FrontEnd catch ${err}. Kontaktujte nás. Váš Novitim.`
      // );
    }
  };
  
  export default route_post_universal;