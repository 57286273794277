import { useState } from "react";
import "../ColorSets/ColorSets.scss";
import useFetch_colroSet_info from "../../../hooks/Pages/UserData/useFetch_colorSet_info";
import NewRespondentResult from "../../../components/pages/UserData/NewRespondent/NewRespondent_Result";
import UserDataListFromLastPageScroll from "../../../components/completeComponents/UserData_listFrom_last_result/UserData_listFromLast_pageScroll/UserData_listFromLast_pageScroll";
import SearchFullResult from "../../../components/elements/Search_FullResult/Search_FullResult";

const NewRespondent = () => {
  const [fetch_result, setFetch_result] = useState([]);

  // new fetch
  const [page, setPage] = useState(1);
  const [limitResponse, setLimitRespose] = useState(10);
  const [search, setSearch] = useState("");

  const phaseRun = "newRespondent";

  // -------------------------------
  //custom hook
  // fetch colroSets info
  
  useFetch_colroSet_info(
    "/find_colorSets_newRespondent",
    "limit",
    limitResponse, // limit on page
    "page",
    page,
    setFetch_result,
    search
  );

  // ---------------------------------------

  return (
    <div className="ColorSets">
      <div className="UserData_main">
        <section className="title">
          <h1>Nevyplněné diagnostiky</h1>
        </section>

        <section className="search">
          <SearchFullResult
            inputType="text"
            inputLabel="Vyhledat jméno nevyplněného respondenta"
            inputID="newRespondent_name"
            inputRegex="universal"
            state={search}
            setState={setSearch}
            wordResultKey="newRespondent_name"
            urlFetch="/find_colorSets_newRespondent"
            setFetch_result={setFetch_result}
          />
        </section>

        <section className="result">
          <NewRespondentResult fetch_result={fetch_result} phaseRun={phaseRun} />
        </section>
      </div>

      {/* Page Scroller */}
      <section className="pageScroll">
        {fetch_result.totalPages && (
          <UserDataListFromLastPageScroll
            fetch_result={fetch_result}
            page={page}
            setPage={setPage}
            limitResponse={limitResponse}
            setLimitRespose={setLimitRespose}
          />
        )}
      </section>
    </div>
  );
};

export default NewRespondent;