const GlobalReducer = (state, action) => {
    switch (action.type) {

      // localStorage loading
    case "SET_G_LOCALSTORAGE_LOADING":
      return {
        ...state,
        g_localStorage_loading: action.payload,
      }

      // Token
    // case "SET_TOKEN":
    //   return {
    //     ...state, 
    //     token: action.payload,
    //   }
      // token active
    case "SET_TOKEN_ACTIVE":
      return {
        ...state,
        token_active: action.payload,
      }

    // Login active Time
    case "SET_ACTIVE_TIME":
      return {
        ...state,
        active_time: action.payload,
      }
      // token create time
    // case "SET_TOKEN_ACTIVE_CREATE_TIME":
    //   return {
    //     ...state,
    //     tokenActive_ceateTime: action.payload,
    //   }
    // --------------------------

    // Admin
  case "SET_ADMIN":
    return {
      ...state,
      admin_name: action.payload.admin_name,
      admin_surname: action.payload.admin_surname,
      admin_phone: action.payload.admin_phone,
      admin_gender: action.payload.admin_gender, 
      admin_email: action.payload.admin_email, 
      admin_rank: action.payload.admin_rank,
      admin_color: action.payload.admin_color,
      admin_position_name: action.payload.admin_position_name,
      admin_company_Name: action.payload.admin_company_Name,
    }

    case "SET_REFRESH_SET_WORDS":
      return {
        ...state,
        refres_setWords:action.payload
      }

    //   // PopUp
    // case "SET_POP_UP":
    //   return {
    //     ...state,
    //     popUp: action.payload,
    //   }

      // error
    case "SET_ERROR":
      return {
        ...state,
        error: action.payload,
      }

      default:
        return state;
    }
  };
  
  export default GlobalReducer;