import { useState, useContext } from "react";
import "./UserData_Result_NumberColorSets.scss";
import { GlobalContext } from "../../../../../global/GlobalContext";
import { f_date_to_cz } from "../../../../../utils/date/f_date_to_cz";
import route_post_universal from "../../../../../routes/global/route_post_universal";
import BtnShowColorSetPopUpColorSet from "../../UserData_listFromLast/UserData_listFromLast_result/UserData_listFormLast_result_btnShowColorSet/BtnShowColorSet_PopUpColorSet/BtnShowColorSet_PopUpColorSet";
import CheckBoxsOnlyShow from "../../../../elements/checkBox/CheckBoxs_onlyShow/CheckBoxs_onlyShow";

const UserData_Result_NumberColorSets = ({ name, fetch_colorSet, setFetch_result }) => {
    const { urlServer} = useContext(GlobalContext)

    const [show, setShow] = useState(false);
    const [fetch_data, setFetch_data] = useState({})

    const f_oneColorSet = async (colroSet_ID) => {
        setShow(true);
        console.log(colroSet_ID)

    try {
        let result = await route_post_universal(
            urlServer,
            "/findOneColorSet",
            "colorSet_ID",
            colroSet_ID
          );
    
          if(result){
            console.log(result)
            setFetch_data(result)
            result = "";
          }
    } catch (err){
        console.log("Front End Error: " + err)
    }
    }

  return (
    <div className="UserData_Result_NumberColorSets">
      <div className="title2">
        <h2>Vyplněné diagnostiky uživatele</h2>
        <h3>{fetch_colorSet.length}</h3>
      </div>

      <section className="allUserColorSet">
        <div className="titles">
            <p>Kolikátý set</p>
            <p className="title_chackBox">Odkonzultováno</p>
            <p>Datum Vytvoření</p>
            <p>Datum dokončení</p>
            <p>Společnost</p>
        </div>
        {fetch_colorSet &&
          fetch_colorSet.map((oneColorSetInfo, index) => {
            const { _id, /*code, admin,*/ company, ready_for_consultation, consultation_complete, feedback_from_respondent, date_complete, date_create, /*tag, wordSet*/ } = oneColorSetInfo;
            const { /*company_ID, */ company_Name } = company;
            // const {admin_company_ID, admin_create_ID} = admin;
            // const {tag_ID, tag_Name} = tag;
            // const {wordSet_ID, wordSet_name} = wordSet;

            return (
              <div key={_id} className="oneColorSet" onClick={() => f_oneColorSet(_id)}>
                <p>{index + 1}</p>
                <CheckBoxsOnlyShow ready_for_consultation={ready_for_consultation} consultation_complete={consultation_complete} feedback_from_respondent={feedback_from_respondent} />
        <p>{f_date_to_cz(date_create)}</p>
        <p title="Datum dokončení">{f_date_to_cz(date_complete)}</p>
        <p>{company_Name}</p>
              </div>
            );
          })}
      </section>

      {/* POP UP COLOR SET */}
      {
        show && fetch_data._id && <BtnShowColorSetPopUpColorSet setShow={setShow} fetch_data={fetch_data} setFetch_data={setFetch_data} name={name} setFetch_result={setFetch_result} />
      }
    </div>
  );
};

export default UserData_Result_NumberColorSets;
