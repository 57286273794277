import "./CheckBoxs_onlyShow.scss"
import CheckBoxonlyShow from '../CheckBox_onlyShow/CheckBox_onlyShow'

const CheckBoxsOnlyShow = ({ready_for_consultation, consultation_complete, feedback_from_respondent}) => {
  return (
    <div className="CheckBoxs_onlyShow">
        <CheckBoxonlyShow value={ready_for_consultation} title="Připraveno pro konzultaci" />
        <CheckBoxonlyShow value={consultation_complete} title="Odkonzultováno" />
        <CheckBoxonlyShow value={feedback_from_respondent} title="Zpětná vazba od respondenta" />
    </div>
  )
}

export default CheckBoxsOnlyShow