import { useState } from "react";
import "../../../../../completeComponents/UserData_listFrom_last_result/UserData_listFrom_last_result.scss"; // table
import "./Tag_Users_Response.scss";
import { f_date_to_cz } from "../../../../../../utils/date/f_date_to_cz";
import { f_date_only } from "../../../../../../utils/date/f_date_only";
import f_initials from "../../../../../../utils/nameAndEmail/f_initials";
import languages_name from "../../../../../../data/user-data/language/languages_name";
import { useOneColorSet } from "../../../../../../hooks/colorSet/useOneColorSet";
import BtnShowColorSetPopUpColorSet from "../../../../UsersData/UserData_listFromLast/UserData_listFromLast_result/UserData_listFormLast_result_btnShowColorSet/BtnShowColorSet_PopUpColorSet/BtnShowColorSet_PopUpColorSet";
import { useOneNewRespondent } from "../../../../../../hooks/newRespondent/useOneNewRespondent";
import NewRespondentOneRespondent from "../../../../../global/NewRespondent/NewRespondent_oneRespondent";
import { useNavigate } from "react-router-dom";
import EmailIconsInfo from "./email/Email_Icons_Info";
import NumberAttempts from "../../../../../elements/number_attempts/Number_Attempts";
import BtnClose from "../../../../../elements/btn/BtnClose/BtnClose";
// import CheckBoxonlyShow from "../../../../../elements/checkBox/CheckBox_onlyShow/CheckBox_onlyShow";
import CheckBoxsOnlyShow from "../../../../../elements/checkBox/CheckBoxs_onlyShow/CheckBoxs_onlyShow";

const TagUsersResponse = ({ fetch_result, setFetch_result }) => {
  const navigate = useNavigate();
  // show email
  const [showEmail, setShowEmail] = useState({});
  // one Color Set
  const [colorSet_ID, setColorSet_ID] = useState({});
  const [newRespondent_ID, setNewRespondent_ID] = useState({});

  // -----------
  // Custom hook
  // colorSet
  const [fetch_data, setFetch_data] = useOneColorSet(colorSet_ID.ID);
  // // newRespondent
  const [fetch_data_newRespondent] = useOneNewRespondent(newRespondent_ID.ID);
  // -----------

  // USER - ID
  const findUser = (userID) => {
    navigate(`../users_data/${userID}`);
  };

  const btn_email = (id) => {
    setShowEmail((prevState) => ({
      ...prevState,
      [id]: !prevState[id], // Přepnutí zobrazení emailu pro konkrétní ID
    }));
  };
  console.log(fetch_result.fetch_data)
  // -----------

  return (
    <div className="UserData_listFrom_last_result Tag_Users_Response">
      {/* one color Set */}
      {colorSet_ID.ID && fetch_data?._id && (
        <BtnShowColorSetPopUpColorSet
          setShow={setColorSet_ID}
          fetch_data={fetch_data}
          setFetch_data={setFetch_data}
          name={colorSet_ID.name}
          setFetch_result={setFetch_result}
        />
      )}
      {/* one respondent */}
      {newRespondent_ID.ID && fetch_data_newRespondent?.fetch_data?._id && (
        <NewRespondentOneRespondent
          fetch_data_newRespondent={fetch_data_newRespondent}
          setNewRespondent_ID={setNewRespondent_ID}
        />
      )}
      {fetch_result.fetch_data && (
        <div className="colorSets">
          <div className="titles">
            <p className="admin">Vytvořil</p>
            <p className="nameEmail">Respondenti</p>
            {/* <p className="title_chackBox">Odkonzultováno</p> */}
            <p>Slovní modul</p>
            <p>Datum vytvoření</p>
            <p>Aktuální stav</p>
            <p className="lastTag">Jazyk</p>
          </div>

          {fetch_result.fetch_data.map((oneTag) => {
            const {
              _id,
              ready_for_consultation,
              consultation_complete,
              feedback_from_respondent,
              admin,
              number_attempts,
              date_create,
              date_complete,
              email,
              name,
              wordSet_name,
              userID,
              userData_actual_language,
              // emails
              noEmail_send,
              email_link_click,
              email_open,
              email_delivered,
              email_bounced,
              email_spam_reported,
              email_dropped,
              email_deferred,
            } = oneTag;

            // language if exist
            const language = languages_name.find(
              (lang) => lang.code === userData_actual_language
            );

            return (
              <div key={_id} className="oneColorSet_info">
                {/* Admin Create */}
                <div className="admin">
                  <div
                    className="admin_box"
                    style={{
                      backgroundColor: admin.color,
                    }}
                  >
                    <p className="admin_name" title={`${admin.name} ${admin.surname}`}>
                      {f_initials(`${admin.name} ${admin.surname}`)}
                    </p>
                  </div>
                </div>

                {/* Name - email */}
                <div className="nameEmail">
                  <p
                    className={`name ${date_complete ? "name-hover" : ""}`}
                    style={{ cursor: date_complete ? "pointer" : "default" }}
                    onClick={() => {
                      if (date_complete) {
                        findUser(userID);
                      }
                    }}
                  >
                    {name}
                  </p>
                  {/* Tlačítko pro zobrazení emailu */}
                  <button className="email_btn" onClick={() => btn_email(_id)}>
                    E-mail
                  </button>
                  {/* Zobrazení emailu podle stavu pro konkrétní ID */}
                  {showEmail[_id] && (
                    <p className="email">
                      {email} <BtnClose setClose={() => btn_email(_id)} />
                    </p>
                  )}
                </div>

                {/* BTN - One color set */}
                <div
                  className="tag_items"
                  onClick={() =>
                    date_complete
                      ? setColorSet_ID({ ID: _id, name })
                      : setNewRespondent_ID({ ID: _id, name })
                  }
                >
                  {/* <div className="ChackBox_box">
                    {consultation_complete ? (
                      <div className="ChackBox">
                        <CheckBoxonlyShow value={consultation_complete} />
                      </div>
                    ) : (
                      <p className="title_chackBox"></p>
                    )}
                  </div> */}

                  <div className="item">
                    <p>{wordSet_name.name}</p>
                  </div>
                  <div className="item">
                    <p title={f_date_to_cz(date_create)}>{f_date_only(date_create)}</p>
                  </div>
                  <div className="item">
                    {/* Actual phase */}

                    {/* date_complete */}
                    <p title={date_complete ? `Datum vyplnění: ${f_date_to_cz(date_complete)}` : ""}>
                      {date_complete && (!ready_for_consultation && !consultation_complete && !feedback_from_respondent) && f_date_only(date_complete)}
                    </p>
                    {/* number attempts */}
                    {!date_complete && number_attempts < 2 && (
                      <NumberAttempts number_attempts={number_attempts} />
                    )}

                    {/* email info */}
                    {!date_complete && number_attempts === 2 && (
                      <EmailIconsInfo
                        noEmail_send={noEmail_send}
                        email_link_click={email_link_click}
                        email_open={email_open}
                        email_delivered={email_delivered}
                        email_bounced={email_bounced}
                        email_spam_reported={email_spam_reported}
                        email_dropped={email_dropped}
                        email_deferred={email_deferred}
                      />
                    )}
                    {
                      (ready_for_consultation || consultation_complete || feedback_from_respondent) &&
                      <CheckBoxsOnlyShow ready_for_consultation={ready_for_consultation} consultation_complete={consultation_complete} feedback_from_respondent={feedback_from_respondent} />
                    }
                  </div>
                  <div className="item item_last lastTag">
                    <p>{language ? language.name : null}</p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default TagUsersResponse;
