import { useState, useContext } from "react"
import "./Profession.scss"
import { GlobalContext } from "../../../global/GlobalContext";
import Search from "../../../components/elements/Search/Search";
import { useRoutePost_FindAll } from "../../../hooks/router/useRoutePost_FindAll";
import route_post_universal from "../../../routes/global/route_post_universal";
import ProfessionResponse from "../../../components/pages/admin_pages/Profession_Response/Profession_Response";

const Profession = () => {
  const {urlServer } = useContext(GlobalContext);
  // const [findProffesion, setFindProffesion] = useState(false);
  const [specialization, setSpecialization] = useState("");
  const [fetch_result, setFetch_result] = useState([]);
  // const [showBtn_create, setShowBtn_create] = useState(false)


  // -------
  // custom hooks
  useRoutePost_FindAll("/findSpecialization_name", specialization, setFetch_result, fetch_result)

  // BTN create
  // useEffect(() => {
  //   if(fetch_result.length === 0 ) {
  //     setShowBtn_create(true)
  //   } else {
  //     setShowBtn_create(false)
  //   }
    
  // },[fetch_result])

  //--------
  // f
  const btnCreate = async () => {
    const response = route_post_universal(
      urlServer,
      "/create_Specialization",
      "specialization",
      specialization
    )

    if(response.msg) {
      console.log(response.msg)
    }
    setSpecialization("")
  }


  // ----------

  return (
    <div className="Profession">
      <div className="title">
        <h1>Profese</h1>
      </div>

      <div className="search">
        <Search 
          inputType="text"
          inputLabel="Zadejte obor"
          inputID="specialization"
          inputRegex="profession"
          state={specialization}
          setState={setSpecialization}
          wordResultKey="specialization_Name"
          urlFetch="/findSpecialization_name"
          fetch_result={fetch_result}
          setFetch_result={setFetch_result}
        />
        <button className={`normalButton`} onClick={btnCreate}>Vytvořit</button>
      </div>

      {/* Main */}
      <div className="profession_main">
        {
          fetch_result.length > 0 && <ProfessionResponse fetch_result={fetch_result} />
        }
        
      </div>
    </div>
  )
}

export default Profession