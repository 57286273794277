import { jsPDF } from "jspdf";
import chartInstances from "../../utils/PDF/graf/f_chart_Instances"; // Import sdíleného objektu
import "./font";
import logo from "./logo_1200.png"
import logo_800 from "./logo_800.png"

const generatePDF = async ({
  id,
  name,
  user_name,
  user_surname,
  date_complete,
  tag_name,
  language,
  redResponse,
  blueResponse,
  greenResponse,
  whiteResponse,
  purpleResponse,
  meanings1,
  meanings2,
  meanings3,
  meanings4,
  meanings5,
  meanings6,
  meanings7,
  meanings8,
  meanings9,
  meaningsColumn
}) => {
  const pdf = new jsPDF({ orientation: "landscape", compress: true }); // Nastavení šířkové orientace + komperese
  const pageWidth = pdf.internal.pageSize.getWidth();
  pdf.setFont("RobotoCondensed-VariableFont_wght");
  const margin = 20; // Levý a pravý okraj stránky

// -------------------------------
// číslování + logo
const addFooterWithPageNumbersAndLogo = (pdf) => {
  const totalPages = pdf.internal.getNumberOfPages(); // Získání celkového počtu stránek
  const pageWidth = pdf.internal.pageSize.getWidth(); // Šířka stránky

  for (let page = 1; page <= totalPages; page++) {
    pdf.setPage(page); // Nastavíme aktuální stránku
    const currentPage = page;
    const footerText = `Novitim | Strana č. ${currentPage} z ${totalPages}`;

    // Přidání textu do zápatí
    pdf.setFontSize(10); // Nastavení velikosti písma
    pdf.text(footerText, pageWidth - 45, 8); // Umístění textu v zápatí

    if (page === 1) continue;
    if (page === 13) continue;
    if (page === 14) continue;
    if (page === 15) continue;

    // Přidání loga
    const logoWidth = 20; // Šířka loga
    const logoHeight = 20; // Výška loga
    const logoX = 5; // Horizontální pozice loga
    const logoY = 5; // Vertikální pozice loga
    pdf.addImage(logo_800, 'PNG', logoX, logoY, logoWidth, logoHeight);
  }
};

  // ----------------------------------------------
  // Stranka 1

  const createFirstPage = (pdf) => {
    // Získání velikosti obrázku
  const logoWidth = 74;  // Nastav šířku obrázku (můžeš přizpůsobit)
  const logoHeight = 74; // Nastav výšku obrázku (můžeš přizpůsobit)

  // Vložení logo nad hlavní nadpis
  const logoX = (pageWidth - logoWidth) / 2; // Horizontální vycentrování
  const logoY = -10; // Vertikální pozice pro logo (můžeš upravit)

  pdf.addImage(logo, 'PNG', logoX, logoY, logoWidth, logoHeight);


    // Nadpis "Diagnostika Novitim Complete"
    pdf.setTextColor(0, 128, 128); // Zelená barva
    pdf.setFontSize(30);
    const mainTitle = "Diagnostika Novitim Complete";
    const mainTitleWidth = pdf.getTextWidth(mainTitle);
    pdf.text(mainTitle, (pageWidth - mainTitleWidth) / 2, 55);
  
    // Text "DŮVĚRNÉ"
    pdf.setTextColor(0, 0, 0); // Černá barva
    pdf.setFontSize(26);
    pdf.text("DŮVĚRNÉ", (pageWidth - pdf.getTextWidth("DŮVĚRNÉ")) / 2, 80);
  
    // Podnadpis: Výstup, datum a kód
    pdf.setFontSize(22);
    pdf.text("Výstup", (pageWidth - pdf.getTextWidth("Výstup")) / 2, 100);
    pdf.text(date_complete, (pageWidth - pdf.getTextWidth(date_complete)) / 2, 110);
    pdf.text(tag_name, (pageWidth - pdf.getTextWidth(tag_name)) / 2, 120);
    pdf.text(language, (pageWidth - pdf.getTextWidth(language)) / 2, 130);
  
    // Jméno: Jiří Horák
    pdf.setFontSize(30);
    pdf.text(name, (pageWidth - pdf.getTextWidth(name)) / 2, 150);
  
    // Poradenský text
    pdf.setFontSize(20);
    const advisoryText =
      "Pro konzultaci a správnou interpretaci výstupu kontaktujte specialistu Marka Zelinu.";
    const wrappedAdvisory = pdf.splitTextToSize(advisoryText, pageWidth - 2 * margin);
    let currentY = 130;
    wrappedAdvisory.forEach((line) => {
      pdf.text(line, (pageWidth - pdf.getTextWidth(line)) / 2, currentY + 40); // Vycentrování textu
      currentY += 6; // Posun na další řádek
    });
  
    // Kontaktní informace
    pdf.setFontSize(22);
    pdf.setTextColor(0, 128, 128); // Zelená barva
    const contactInfo = "Marek Zelina  |  +420 603 479 232  |  marek@novitim.cz";
    pdf.text(contactInfo, (pageWidth - pdf.getTextWidth(contactInfo)) / 2, currentY + 50); // Vycentrování kontaktních informací

    // addFooterWithPageNumbers(pdf);
  };
  
  // Použití:
  createFirstPage(pdf);
  pdf.setTextColor(0, 0, 0); // Černá barva
  pdf.addPage();
  // ----------------------------------------------
  
  // Stranka 2
  const createSecondPageLandscape = (pdf) => {
    const pageWidth = 297;  // Šířka A4 na šířku v mm
    const margin = 15; // Okraje stránky
    const lineHeight = 6; // Výška každého řádku textu
    const sectionSpacing = 12; // Mezery mezi sekcemi
    const textWidth = pageWidth - 2 * margin; // Šířka, kterou můžeme využít pro text
    const initialY = 40; // Počáteční pozice pro text (posunuto na 50 mm dolů)

    pdf.setFontSize(25);
    pdf.text("Úvod", pageWidth / 2, 20, { align: "center" });
  
    // Nastavení písma a barvy
    pdf.setFontSize(15); // Zvýšeno na 14
    pdf.setTextColor(0, 0, 0); // Černá barva textu
  
    let currentY = initialY; // Začneme o 50 mm níže
  
    // Funkce pro zalomení a vypsání textu
    const writeText = (text) => {
      const wrappedText = pdf.splitTextToSize(text, textWidth); // Zalomí text na šířku stránky
      wrappedText.forEach(line => {
        pdf.text(line, margin, currentY);
        currentY += lineHeight; // Posun na další řádek
      });
      currentY += sectionSpacing; // Mezery mezi odstavci
    };
  
    // Volání funkce pro každý odstavec
    writeText("Při čtení výstupu diagnostiky Novitim mějte na paměti:");
    writeText("1. Žádné naměřené hodnoty nelze označit za „dobré“ nebo „špatné“. Hodnoty je nutné interpretovat v kontextu osobních a pracovních aspirací člověka a konkrétního osobního či pracovního prostředí. Hodnoty klíčové pro jednu profesi a konkrétní firmu mohou být naopak překážkou při výkonu jiných zaměstnání nebo v odlišně řízených firmách.");
    writeText("2. Žádné hodnoty nejsou neměnné a výkon osoby lze pozitivně změnit. Pochopení člověka včetně jeho silných a slabých stránek, k čemuž tento diagnostika poskytuje klíčové informace, nám umožňuje zvolit efektivní strategii osobního rozvoje a jejího správného vedení. Nehledejte tedy v tomto výstupu typologii osobnosti, ale reálného pomocníka pro lepší pracovní uplatnění.");
    writeText("3. Člověk je zpravidla součástí skupin. Všechny naměřené hodnoty proto popisují postoje a chování člověka v rámci skupin a v konkrétních podmínkách. Jedná se o vzájemné působení (interakci) člověka a okolí. Pro efektivní fungování skupiny jako celku je důležité zastoupení různých týmových rolí a různých postojů. Tato rozmanitost je podmínkou pro vytváření optimálního týmu, ale může být také zdrojem konfliktů.");
    writeText("4. Tento dokument obsahuje citlivé osobní informace. Dbejte důsledně na etické zacházení a diskrétnost, včetně zákona o ochraně osobních dat.");
    writeText("5. Pokud budete potřebovat, pomůžeme Vám s výkladem naměřených hodnot. Pro konzultaci a správnou interpretaci výstupu kontaktujte specialistu Marka Zelinu (+420 603 479 232, marek@novitim.cz)");

    pdf.addPage(); // Přidání nové stránky, pokud bude potřeba
  };
  
  createSecondPageLandscape(pdf);
  // ----------------------------------------------------------------
  const chart8Data = chartInstances[`${id}_8`];
  if (chart8Data) {
    const { chart, grafTitle } = chart8Data;
    console.log(chart.el)
  
    // Přidání třetí stránky a vykreslení grafu 8
    if (pdf.getNumberOfPages() < 3) {
      pdf.addPage(); // Přidá třetí stránku, pokud ještě neexistuje
    }
    pdf.setPage(3); // Přepne na třetí stránku
  
    // Parametry nadpisu
    pdf.setFontSize(16);
    const wrappedText = pdf.splitTextToSize(grafTitle || "", pageWidth - 20);
    wrappedText.forEach((line, index) => {
      const textWidth = pdf.getTextWidth(line);
      pdf.text((pageWidth - textWidth) / 2, 10 + index * 6, line); // Nadpis uprostřed nahoře
    });
  
    // Získání dat grafu jako URI
    const { imgURI } = await chart.dataURI({ scale: 1.5 }); // Zajistí vyšší kvalitu obrázku
  
    // Získání rozměrů grafu
    let originalWidth = 600; // Výchozí šířka
    let originalHeight = 400; // Výchozí výška
  

    if (chart.el) {
      const rect = chart.el.getBoundingClientRect(); // Načte rozměry DOM elementu grafu
      originalWidth = rect.width || originalWidth;
      originalHeight = rect.height || originalHeight;
    }

    console.log(originalWidth)
  
    // Zmenšení na polovinu
    const chartWidth = originalWidth / 5;
    const chartHeight = originalHeight / 5;
  
    // Výpočet pozice grafu pro zarovnání na střed a posun o 80 bodů výše
    const chartX = (pageWidth - chartWidth) / 2; // Středová horizontální pozice
    const chartY = 20; // Vertikální pozice grafu
  
    // Přidání grafu do PDF na třetí stránku
    pdf.addImage(imgURI, "PNG", chartX, chartY, chartWidth, chartHeight);

    // ------------------
    // meanings
// Přidání textů pod graf
const textStartX = 20; // Začátek prvního textového bloku
const textWidth = (pageWidth - 50) / 3; // Šířka jednoho bloku (tři bloky se stejnou šířkou, s mezerami mezi nimi)
const blockHeight = 45; // Výška jednoho textového bloku
const blockSpacingX = 5; // Mezera mezi bloky na šířku
const lineSpacing = 6; // Rozestup mezi řádky textu
const blockSpacingY = 10; // Mezera mezi řádky bloků na výšku
const blockPadding = 5; // Vnitřní odsazení textu v bloku

const meanings = meanings8 || [];
let currentY = 150; // Počáteční vertikální pozice pro texty

// Rozdělení významů na skupiny po třech pro zobrazení vedle sebe
for (let i = 0; i < meanings.length; i += 3) {
  const row = meanings.slice(i, i + 3); // Získá tři texty pro aktuální řádek
  const localY = currentY; // Lokální kopie vertikální pozice pro tento řádek

  row.forEach((meaning, index) => {
    const blockX = textStartX + (textWidth + blockSpacingX) * index; // Vypočítá horizontální pozici bloku

    // Nakreslení podbarvení bloku
    pdf.setFillColor(233, 233, 233);
    pdf.roundedRect(blockX, localY, textWidth, blockHeight, 3, 3, "F"); // Ohraničení bloku s výplní

    // Nastavení fontu a barvy textu
    pdf.setTextColor(0, 0, 0);

    // Nadpis významu
    pdf.setFontSize(16); // Velikost písma nadpisu
    const title = meaning.role || meaning.title || "";
    pdf.text(title, blockX + blockPadding, localY + blockPadding + lineSpacing);

    // Popis významu
    pdf.setFontSize(12); // Velikost písma textu
    const description = meaning.meaning || meaning.description || "";
    const wrappedDescription = pdf.splitTextToSize(description, textWidth - 2 * blockPadding); // Text zalomený podle šířky bloku
    let yOffset = localY + blockPadding + 5 + lineSpacing * 2; // Posun na další řádek pod nadpis

    wrappedDescription.forEach((line) => {
      pdf.text(line, blockX + blockPadding, yOffset);
      yOffset += lineSpacing;
    });
  });

  currentY += blockHeight + blockSpacingY; // Posun na další řádek bloků
}
  }
  

  // --------------------------------------------------------

  // Stránka 4+: Grafy 1–7 a 9
  const getMeaningsForChart = (chartIndex) => {
    switch (chartIndex) {
      case 1: return meanings1;
      case 2: return meanings2;
      case 3: return meanings3;
      case 4: return meanings4;
      case 5: return meanings5;
      case 6: return meanings6;
      case 7: return meanings7;
      case 8: return meanings8;
      case 9: return meanings9;
      default: return null;
    }
  };

  for (let i = 1; i <= 9; i++) {
    if (i === 8) continue; // Graf 8 již byl vykreslen
    const chartId = `${id}_${i}`;
    const chartData = chartInstances[chartId];
    if (chartData) {
        const { chart, grafTitle } = chartData;

        pdf.addPage();
        // addFooterWithPageNumbers(pdf);

        // Nastavení pro každý graf a text
        const customSettings = {
            1: { chartWidth: pageWidth - 10, chartHeight: 120, chartX: 5, chartY: 12, textStartY: 145, textX: 15, textWidth: pageWidth - 20 },
            2: { chartWidth: pageWidth - 30, chartHeight: 110, chartX: 15, chartY: 12, textStartY: 145, textX: 15, textWidth: pageWidth - 40 },
            3: { chartWidth: pageWidth - 40, chartHeight: 110, chartX: 20, chartY: 12, textStartY: 150, textX: 20, textWidth: pageWidth - 30 },
            4: { chartWidth: pageWidth - 40, chartHeight: 110, chartX: 15, chartY: 12, textStartY: 150, textX: 20, textWidth: pageWidth - 40 },
            5: { chartWidth: pageWidth - 120, chartHeight: 190, chartX: 60, chartY: 12, textStartY: 175, textX: 15, textWidth: pageWidth - 50 },
            6: { chartWidth: pageWidth - 90,chartHeight: 160, chartX: 40, chartY: 12, textStartY: 170, textX: 10, textWidth: pageWidth - 20 },
            7: { chartWidth: pageWidth - 20, chartHeight: 110, chartX: 10, chartY: 15, textStartY: 150, textX: 25, textWidth: pageWidth - 50 },
            9: { chartWidth: pageWidth - 20, chartHeight: 110, chartX: 10, chartY: 20, textStartY: 155, textX: 25, textWidth: pageWidth - 50 },
        };

        const { chartWidth, chartHeight, chartX, chartY, textStartY, textX, textWidth } = customSettings[i] || {
            chartWidth: pageWidth - 40,
            chartHeight: 150,
            chartX: 10,
            chartY: 20,
            textStartY: 140,
            textX: 10,
            textWidth: pageWidth - 20,
        };

        // Nadpis grafu
        pdf.setFontSize(16);
        const wrappedText = pdf.splitTextToSize(grafTitle || "", chartWidth);
        wrappedText.forEach((line, index) => {
            const textWidth = pdf.getTextWidth(line);
            pdf.text((pageWidth - textWidth) / 2, 10 + index * 6, line);
        });

        // Vykreslení grafu s vlastní šířkou, výškou a horizontálním posunem
        const { imgURI } = await chart.dataURI({ scale: 1.5 });
        pdf.addImage(imgURI, "PNG", chartX, chartY, chartWidth, chartHeight);

        // Významy pod grafem nebo na nové stránce
        const meanings = getMeaningsForChart(i);
        if (Array.isArray(meanings)) {
          if (i === 5) {
            // Významy pro graf 5 na nové stránce
            pdf.addPage();
            pdf.setFontSize(16);
        
            // Vycentrování nadpisu "Významy pro Graf 5"
            const title = "Významy pro graf Kritické myšlení";
            const titleWidth = pdf.getTextWidth(title);
            const titleX = (pageWidth - titleWidth) / 2; // Vypočítaný střed stránky
            pdf.text(title, titleX, 10);
        
            let yStart = 60; // Startovací výška pro text na nové stránce
            meanings.forEach((meaning, index) => {
                pdf.setFontSize(19);
        
                // Zarovnání nadpisu významu vlevo
                const heading = meaning.role || meaning.title || "";
                pdf.text(heading, margin, yStart); // Zarovnáno vlevo
                yStart += 10; // Posun pro popis
        
                pdf.setFontSize(15);
                // Popis významu - zarovnaný doleva
                const description = meaning.meaning || meaning.description || "";
                const wrappedDescription = pdf.splitTextToSize(description, textWidth);
                wrappedDescription.forEach((line, lineIndex) => {
                    pdf.text(line, margin, yStart + lineIndex * 6); // Text popisu je zarovnaný vlevo
                });
        
                // Přidání mezery pro další význam, přičemž po prvním významu přidáme +5
                yStart += wrappedDescription.length * 6 + 10 + (index === 0 ? 10 : 0); // Pokud je první význam, přidáme +5
            });
            } else {
                // Významy pod grafem (standardní chování)
                let yStart = textStartY; // Startovací výška pro text
                meanings.forEach((meaning, index) => {
                    pdf.setFontSize(10);

                    // Nadpis významu
                    pdf.text(meaning.role || meaning.title || "", textX, yStart + index * 20);

                    // Popis významu
                    const description = meaning.meaning || meaning.description || "";
                    const wrappedDescription = pdf.splitTextToSize(description, textWidth);
                    wrappedDescription.forEach((line, lineIndex) => {
                        pdf.text(line, textX, yStart + 10 + index * 20 + lineIndex * 6);
                    });
                });
            }
        } else if (meanings) {
            // Jednoduchý objekt s významy
            let yStart = textStartY;
            pdf.setFontSize(14);
            const { title, description, practice } = meanings;
            pdf.text(`${title || ""}`, textX, yStart);

            pdf.setFontSize(10);
            const wrappedDescription = pdf.splitTextToSize(`${description || ""}`, textWidth);
            wrappedDescription.forEach((line, index) => {
                pdf.text(line, textX, yStart + 10 + index * 6);
            });

            if (practice) {
                const wrappedPractice = pdf.splitTextToSize(`${practice}`, textWidth);
                wrappedPractice.forEach((line, index) => {
                    pdf.text(line, textX, yStart + 13 + wrappedDescription.length * 6 + index * 6);
                });
            }
        }
    } else {
        console.warn(`Chart with ID ${chartId} not found.`);
    }
  }
  pdf.addPage();

  // --------------------------
  // words column
  const drawTableWithPagination = (x, y, width, allItems, bgColors, titles, pdf) => {
    const rotatedTextMaxLength = 25; // Maximální délka otočeného textu
    const rotatedTextSpacing = 4; // Vzdálenost mezi otočenými texty
    const maxRowWidth = width - 10; // Maximální šířka řádku (bez mezer)
    const rowHeight = 34; // Výška řádku
  
    // Pomocná funkce pro vykreslení jednoho řádku
    const renderRow = (x, y, items, bgColor, title) => {
      pdf.setFillColor(...bgColor);
      pdf.setDrawColor(0, 0, 0);
      pdf.setLineWidth(0.5);
      pdf.roundedRect(x, y, width, rowHeight, 5, 5, "FD");
  
      pdf.setTextColor(0, 0, 0);
      pdf.setFontSize(16);
      pdf.saveGraphicsState();
      pdf.text(title, x + 7, y + 30, { angle: 90 });
      pdf.restoreGraphicsState();
  
      pdf.setLineWidth(0.5);
      pdf.line(x + 8, y + 30, x + 8, y + 6);
  
      pdf.setFontSize(10);
  
      let currentX = x + 15;
      const remainingItems = [];
      items.forEach((item) => {
        const text = Array.isArray(item) ? item.join(", ") : item || "N/A";
        const wrappedText = pdf.splitTextToSize(text, rotatedTextMaxLength);
  
        wrappedText.forEach((line) => {
          if (currentX + rotatedTextSpacing > x + maxRowWidth) {
            remainingItems.push(line); // Přesahující text
            return;
          }
          pdf.text(line, currentX, y + 30, { angle: 90 });
          currentX += rotatedTextSpacing;
        });
      });
  
      return remainingItems; // Vrací zbývající text
    };
  
    // Funkce pro vykreslení celé tabulky včetně stránkování
    const renderPage = (x, y, allItems, bgColors, titles) => {
      let pageRemainingItems = allItems.map((items, i) => renderRow(x, y + i * (rowHeight + 3), items, bgColors[i], titles[i]));
  
      while (pageRemainingItems.some((items) => items.length > 0)) {
        pdf.addPage();
        const newRemainingItems = pageRemainingItems.map((items, i) => {
          const rowItems = [...items]; // Vytvoří lokální kopii pro každou iteraci
          return renderRow(x, y + i * (rowHeight + 3), rowItems, bgColors[i], titles[i]);
        });
        pageRemainingItems = newRemainingItems;
      }
    };
  
    // Spustit vykreslování
    renderPage(x, y, allItems, bgColors, titles);
  };
  
  // Parametry pro tabulku
  const rowWidth = pageWidth - 20; // Šířka přes celou stránku (včetně mezer)
  const rowYStart = 15; // Počáteční pozice prvního řádku
  
  // Data, barvy a názvy sloupců
  const allItems = [purpleResponse, whiteResponse, greenResponse, blueResponse, redResponse];
  const bgColors = [
    [173, 216, 230],
    [144, 238, 144],
    [255, 255, 255],
    [144, 238, 144],
    [173, 216, 230],
  ];
  const titles = ["Destruktory", "Stresory", "Neutralita", "Motivace", "Ideály"];
  
  // Vykreslení tabulky
  drawTableWithPagination(10, rowYStart, rowWidth, allItems, bgColors, titles, pdf);
  
  // Přidání nadpisu
  pdf.setFontSize(16);
  pdf.text(`Slovní schéma`, pageWidth / 2 - 16, 10);
  
  // -------------------------------------------
    // Meanings texts columns words
    const addMeaningsPage = (pdf, meaningsColumn) => {
      pdf.addPage(); // Přidání nové stránky
      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();
    
      const margin = 15; // Levý a pravý okraj stránky
      const textWidth = pageWidth - 2 * margin - 6; // Maximální šířka textu (s paddingem 3)
      const lineSpacing = 6; // Mezera mezi řádky uvnitř bloku
      const blockSpacing = 8; // Snížená mezera mezi bloky
      const padding = 3; // Padding kolem textu
    
      // Výpočet celkové výšky všech bloků
      const totalBlockHeight = meaningsColumn.reduce((acc, { title, description }) => {
        const wrappedTitle = pdf.splitTextToSize(title, textWidth);
        const titleHeight = wrappedTitle.length * lineSpacing;
    
        const wrappedDescription = pdf.splitTextToSize(description, textWidth);
        const descriptionHeight = wrappedDescription.length * lineSpacing;
    
        return acc + titleHeight + descriptionHeight + blockSpacing + 2 * padding; // Výška bloku včetně paddingu
      }, 0);
    
      // Počáteční vertikální pozice pro centrování všech bloků (posuneme o 25 bodů dolů)
      let currentY = (pageHeight - totalBlockHeight) / 2 + 10 + 15 + 5 + 5; // Posunuto o 25 bodů dolů (15 + 5 + 5)
    
      const bgColors = [
        [173, 216, 230],
        [144, 238, 144],
        [255, 255, 255],
        [144, 238, 144],
        [173, 216, 230]
      ];
    
      meaningsColumn.forEach(({ title, description }, index) => {
        pdf.setFontSize(14);
        pdf.setTextColor(0, 0, 0);
    
        // Zabalení textu nadpisu
        const wrappedTitle = pdf.splitTextToSize(title, textWidth);
        const titleHeight = wrappedTitle.length * lineSpacing;
    
        pdf.setFontSize(10);
    
        // Zabalení textu popisu
        const wrappedDescription = pdf.splitTextToSize(description, textWidth);
        const descriptionHeight = wrappedDescription.length * lineSpacing;
    
        // Celková výška bloku (včetně paddingu)
        const blockHeight = titleHeight + descriptionHeight + 2 * padding;
    
        // Vykreslení pozadí
        const bgColor = bgColors[index % bgColors.length];
        pdf.setFillColor(...bgColor);
        pdf.setDrawColor(0, 0, 0); // Černá barva pro ohraničení
        pdf.setLineWidth(0.5); // Tloušťka čáry
        pdf.roundedRect(
          margin - padding, // X pozice (včetně levého paddingu)
          currentY - padding, // Y pozice (včetně horního paddingu)
          pageWidth - 2 * margin + 2 * padding, // Šířka bloku (včetně levého a pravého paddingu)
          blockHeight, // Výška bloku (včetně horního a spodního paddingu)
          3, // Zaoblení rohů
          3,
          "FD" // Vykreslení výplně (F) a ohraničení (D)
        );
    
        // Vykreslení nadpisu
        pdf.setFontSize(14);
        pdf.text(wrappedTitle, margin, currentY + 5); // Text nadpisu posunut o 5 bodů výše
        currentY += titleHeight + lineSpacing;
    
        // Vykreslení popisu
        pdf.setFontSize(10);
        pdf.text(wrappedDescription, margin, currentY); // Text popisu posunut zpět na normální výšku
        currentY += descriptionHeight + blockSpacing; // Menší mezera mezi bloky
      });
    
      pdf.setFontSize(16);
      pdf.text(`Slovní schéma - význam`, pageWidth / 2 - 25, 10);
    };    
    
    
    // Použití:
    addMeaningsPage(pdf, meaningsColumn);  
    addFooterWithPageNumbersAndLogo(pdf);
  // -------------------------------------------
  pdf.save(`${user_surname} ${user_name} - Diagnostika Novitim Complete.pdf`);
  
};

export default generatePDF;