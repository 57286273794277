import React from 'react'
import "./Btn_Show.scss"
import { SlArrowDown } from "react-icons/sl";

const BtnShow = ({text, value, set}) => {
  return (
    <div className="Btn_Show">
        <button className="userData_show" onClick={() => set(!value)}>{value ? "Skrýt" : "Zobrazit"} {text}<SlArrowDown /> </button>
        
    </div>
  )
}

export default BtnShow