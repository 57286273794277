import { useContext, useState } from "react";
import { NavLink } from "react-router-dom";
import { GlobalContext } from "../../../../global/GlobalContext";
import "./NavUserData.scss";
import { FaUser, FaLock } from "react-icons/fa";
import { BsClipboardCheckFill, BsClipboardXFill } from "react-icons/bs";
import { FaTag } from "react-icons/fa";
import { RiCommunityLine } from "react-icons/ri";
// import rank_data from "../../../../data/user-data/rank_data";
import BtnNewRespondent from "../BtnNewRespondent/BtnNewRespondent";

import PopupMedium from "../../../elements/popUp/PopUp_Medium/PopUp_Medium";
import UserSetting from "./User_Setting/User_Setting";


const NavUserData = () => {
  const { admin_name, admin_surname, admin_email, admin_rank, admin_color, admin_position_name } =
    useContext(GlobalContext);

    const [showUserSetting, setShowUserSetting] = useState(false);

    // const rank = rank_data.find(r => r.value === admin_rank)

  return (
    <nav className="NavUserData">
      <div className="NavUserData_main">
        {
          // Only for admin 0, 1, 2
          admin_rank < 3 && (
            <div className="admin_change">
              <NavLink to="/admin_data" className="normalButton">
                <div className="hover_checkout">
                  <FaLock />
                  <p className="hover_text">Admin</p>
                </div>
              </NavLink>
            </div>
          )
        }

        {/*  Create NewRespndent */}
        <div className="newRespondent">
          <BtnNewRespondent />
        </div>

        <div className="nav-box">

          {/* ColorSets */}
          <NavLink
            to="color_sets"
            className={({ isActive }) => (isActive ? "active" : "")}
          >
            <BsClipboardCheckFill className="icon" /> Vyplněné diagnostiky
            <div className="colorBox colorBox_top"></div>{" "}
            {/* Testovací čtvereček */}
            <div className="colorBox colorBox_bottom"></div>{" "}
            {/* Testovací čtvereček */}
            <div className="colorWhite"></div>
          </NavLink>

          {/* NewRespondent */}
          <NavLink
            to="color_sets_unfilled"
            className={({ isActive }) => (isActive ? "active" : "")}
          >
            <BsClipboardXFill className="icon" /> Nevyplněné diagnostiky
            <div className="colorBox colorBox_top"></div>{" "}
            {/* Testovací čtvereček */}
            <div className="colorBox colorBox_bottom"></div>{" "}
            {/* Testovací čtvereček */}
            <div className="colorWhite"></div>
          </NavLink>

          {/* Tags */}
          <NavLink
            to="tags"
            className={({ isActive }) => (isActive ? "active" : "")}
          >
            <FaTag className="icon" /> Tagy
            <div className="colorBox colorBox_top"></div>{" "}
            {/* Testovací čtvereček */}
            <div className="colorBox colorBox_bottom"></div>{" "}
            {/* Testovací čtvereček */}
            <div className="colorWhite"></div>
          </NavLink>

          {/* Company */}
          <NavLink
            to="companies"
            className={({ isActive }) => (isActive ? "active" : "")}
          >
            <RiCommunityLine className="icon company_icon"/> Společnosti
            <div className="colorBox colorBox_top"></div>{" "}
            {/* Testovací čtvereček */}
            <div className="colorBox colorBox_bottom"></div>{" "}
            {/* Testovací čtvereček */}
            <div className="colorWhite"></div>
          </NavLink>

          {/* Respondents search */}
          <NavLink
            to="users_data"
            className={({ isActive }) => (isActive ? "active" : "")}
          >
            <FaUser className="icon" /> Vyplnění respondenti
            <div className="colorBox colorBox_top"></div>{" "}
            {/* Testovací čtvereček */}
            <div className="colorBox colorBox_bottom"></div>{" "}
            {/* Testovací čtvereček */}
            <div className="colorWhite"></div>
          </NavLink>
        </div>
      </div>

      <div className="NavUserData_bottom">
        <div
          style={{ border: `2px solid ${admin_color}` }}
          className="admin_details"
          title="Změna hesla"
          onClick={() => setShowUserSetting(true)}
        >
          <p className="name">{admin_name + " " + admin_surname}</p>
          <p className="email">{admin_email}</p>
          <p className="rank">{admin_position_name}</p>
        </div>
      </div>

      {
        showUserSetting && <PopupMedium setClose={setShowUserSetting} title="Nastavení uživatele" >
          <UserSetting />
        </PopupMedium>
      }
    </nav>
  );
};

export default NavUserData;
