import { useRef } from "react";
import Chart from "react-apexcharts";
import "../Graf/Graf.scss";
import chartInstances from "../../../../../../../../utils/PDF/graf/f_chart_Instances";
import "./Graf_Radar.scss";

// Tento objekt bude obsahovat instance všech grafů
// const chartInstances = {};

const GrafRadar = ({
  id,
  grafTitle,
  grafTitle_bottom,
  text,
  name,
  data,
  // meaningsText,
  // setTopThreeMeanings,
  // topThreeMeanings,
  meaning_text,
}) => {
  const chartRef = useRef(null);


  // Uložení instance grafu do chartInstances
  chartInstances[id] = {
    chart: chartRef.current?.chart,
    grafTitle: grafTitle,
  };

// Seřazení dat a textů na základě hodnoty (od největší po nejmenší)
const sortedDataWithText = [...data]
  .map((value, index) => ({
    value: value !== null && value !== undefined ? value : 0, // Nahrazení neplatné hodnoty výchozí
    text: text[index] || '', // Zajištění, že text nebude undefined
    // meaning: meaningsText[index], // správný klíč pro významové texty
  }))
  .sort((a, b) => b.value - a.value); // Seřazení podle hodnoty sestupně

const sortedData = sortedDataWithText.map((item) => item.value); // Seřazené hodnoty
const sortedText = sortedDataWithText.map((item) => item.text); // Seřazené texty

const seriesData = [
  { 
    name: grafTitle_bottom || 'Default Title', // Zajištění, že název nebude undefined
    data: sortedData || [] // Pokud je sortedData prázdné, vrátí prázdné pole
  }
];

  return (
    <div className="Graf" id={id}>
      <h4>{grafTitle}</h4>
      <Chart
        ref={chartRef}
        type="radar"
        width="100%"
        height="650px"
        series={seriesData}
        options={{
          chart: {
            type: "radar",
            toolbar: {
              show: true,
            },
            events: {
              mounted: (chart) => {
                chartInstances[id] = {
                  chart,
                  grafTitle: grafTitle,
                };
                console.log(`Chart for ${id} successfully mounted:`, chart);
              },
            },
          },
          xaxis: {
            title: { text: name }, // Zobrazení popisku pod grafem
            categories: sortedText, // Seřazené popisky
            labels: {
              style: {
                fontSize: "13px",
                colors: [
                  "#001b96",
                  "#001b96",
                  "#001b96",
                  "#5e5e5e",
                  "#5e5e5e",
                  "#5e5e5e",
                  "#5e5e5e",
                  "#5e5e5e",
                  "#5e5e5e",
                  "#5e5e5e",
                  "#5e5e5e",
                  "#5e5e5e",
                  "#5e5e5e",
                  "#5e5e5e",
                  "#5e5e5e",
                  "#5e5e5e",
                  "#5e5e5e",
                  "#5e5e5e",
                  "#5e5e5e",
                  "#5e5e5e",
                ],
              },
            },
          },
          yaxis: {
            min: 0, // Nastavení minima na 0
            max: 8, // Nastavení maxima na 8
            show: false, // Skrytí čísel osy Y
            labels: {
              show: false, // Skrytí hodnot osy Y
              formatter: function (value) {
                return value.toFixed(2); // Formátování na dvě desetinná místa
              },
            },
          },
          tooltip: {
            y: {
              formatter: function (value) {
                return value.toFixed(2); // Formátování na dvě desetinná místa v tooltipu
              },
            },
          },
          dataLabels: {
            enabled: false, // Skrytí hodnot v grafu
            formatter: function (value) {
              return value.toFixed(2); // Formátování na dvě desetinná místa
            },
            style: {
              fontSize: "12px",
              colors: ["#000"],
            },
          },
          plotOptions: {
            radar: {
              polygons: {
                strokeColors: "#e9e9e9",
                fill: {
                  colors: ["#d8d8d8", "#fff"],
                },
              },
            },
          },
        }}
      />
      {/* Meaning graf 8 */}
      <div className="meaningText_Graf8">
        {meaning_text.map((oneMeaning, index) => {
          return (
            <div key={index} className="meaningText_Graf8_one">
              <p className="graf8_title">{oneMeaning.role}</p>
              <p>{oneMeaning.meaning}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default GrafRadar;
