// import Graf from "./Graf/Graf";
import GrafRadar from "./Graf_Radar/Graf_Radar";
import GrafVertical from "./Graf_Vertical/Graf_Vertical";
import "./GrafData_DataForGraf.scss";

const GrafData_DataForGraf = ({
  grafData,
  id,
  // // graf 1 (meanings)
  // meanings1,
  // setMeanings1,
  // // graf 2 (meanings)
  // meanings2,
  // setMeanings2,
  // // graf 3 (meanings)
  // meanings3,
  // setMeanings3,
  // // graf 4 (meanings)
  // meanings4,
  // setMeanings4,
  // graf 5 (static)
  inGroup_consciousness_staticTexty,
  // // graf 6 (meanings)
  // meanings6,
  // setMeanings6,
  // // graf 6 (meanings)
  // meanings7,
  // setMeanings7,
  //graf 8 (meanings)
  // setTopThreeMeanings,
  // topThreeMeanings,
}) => {
    // Destructuring Graf Data
    const {
      graf1,
      graf2,
      graf3,
      graf4,
      graf5,
      graf6,
      graf7,
      graf8,
      graf9
    } = grafData


  // ----------------------------------------------------------------
  return (
    <div className="GrafData_DataForGraf">
      <div className="line">
        <GrafRadar
          id={`${id}_8`}
          // name={name}
          text={graf8.coolrGraf_text2}
          grafTitle={graf8.coolGraf_title}
          grafTitle_bottom={graf8.coolGraf_title_bottom}
          data={graf8.coolGraf_indexs_values_procent}
          // meaningsText={graf8.coolGraf_sortedRoleMeaning}
          // setTopThreeMeanings={setTopThreeMeanings}
          // topThreeMeanings={topThreeMeanings}
          meaning_text={graf8.textSort}
        />
      </div>
      <div className="line">
        {/* 1 */}
        <GrafVertical
          id={`${id}_1`}
          // name={name}
          text={graf1.wordsColor_GroupC_graf_text}
          grafTitle={graf1.wordsColor_GroupC_graf_title}
          grafTitle_bottom={graf1.wordsColor_GroupC_graf_titleBottom}
          data={graf1.wordsColor_GroupC_behavioralTrend}
          data2={graf1.wordsColor_GroupC_trendOfExperiencing}
          graf_height="500"
          // meaningsText={graf1.wordsColor__meaningText}
          // number_meaningsText={1}
          // setMeanings={setMeanings1}
          // meanings={meanings1}
          meaning_text={graf1.textSort}
        />
      </div>
      <div className="line">
        {/* 2 */}
        <GrafVertical
          id={`${id}_2`}
          // name={name}
          text={graf2.groupConsciousness_text}
          grafTitle={graf2.groupConsciousness_graf_title}
          grafTitle_bottom={graf2.groupConsciousness_graf_titleBottom}
          data={graf2.groupConsciousness_trendOfExperiencing}
          graf_height="500"
          // meaningsText={graf2.groupConsciousness_meaningText}
          // number_meaningsText={1}
          // setMeanings={setMeanings2}
          // meanings={meanings2}
          meaning_text={graf2.textSort}
        />
      </div>
      <div className="line">
        {/* 3 */}
        <GrafVertical
          id={`${id}_3`}
          // name={name}
          text={graf3.processingReality_text}
          grafTitle={graf3.processingReality_graf_title}
          grafTitle_bottom={graf3.processingReality_graf_titleBottom}
          data={graf3.processingReality_internalTrends}
          graf_height="500"
          // meaningsText={graf3.processingReality_graf_meanings_texts}
          // number_meaningsText={1}
          // setMeanings={setMeanings3}
          // meanings={meanings3}
          meaning_text={graf3.textSort}
        />
        
      </div>
      <div className="line">
        {/* 4 - systém */}
        <GrafVertical
          id={`${id}_4`}
          // name={name}
          text={graf4.system_text}
          grafTitle={graf4.system_graf_title}
          grafTitle_bottom={graf4.system_graf_titleBottom}
          data={graf4.system_behavioral_trend}
          data2={graf4.system_trendOfExperiencing}
          graf_height="500"
          // meaningsText={graf4.system_graf_meaningsTexts}
          // number_meaningsText={1}
          // setMeanings={setMeanings4}
          // meanings={meanings4}
          meaning_text={graf4.textSort}
        />
      </div>
      <div className="line">
        {/* 5 - Na skupinové vědomí */}
        <GrafVertical
          id={`${id}_5`}
          // name={name}
          text={graf5.inGroup_consciousness_text}
          grafTitle={graf5.inGroup_consciousness_grafTitle}
          grafTitle_bottom={graf5.inGroup_consciousness_grafBottom}
          data={graf5.inGroup_consciousness_behavioralTrend}
          data2={graf5.inGroup_consciousness_trendOfExperiencing}
          graf_height="1500"
          staticTexts={inGroup_consciousness_staticTexty}
        />
      </div>
      <div className="line">
        {/* 6 - systém */}
        <GrafVertical
          id={`${id}_6`}
          // name={name}
          text={graf6.noName2_extra_intro_text}
          grafTitle={graf6.noName2_extra_intro_grafTitle}
          grafTitle_bottom={graf6.noName2_extra_intro_grafBottom}
          data={graf6.noName2_extra_intro_disintegrationExternal}
          data2={graf6.noName2_extra_intro_internalDisintegration}
          graf_height="1100"
          // meaningsText={graf6.noName2_extra_meaningsTexts}
          // number_meaningsText={1}
          // setMeanings={setMeanings6}
          // meanings={meanings6}
          meaning_text={graf6.textSort}
        />
      </div>
      <div className="line">
        <GrafVertical
          id={`${id}_7`}
          // name={name}
          text={graf7.segmentsOfConsciousness_text}
          grafTitle={graf7.segmentsOfConsciousness_grafTitle}
          grafTitle_bottom={graf7.segmentsOfConsciousness_grafBottom}
          data={graf7.segmentsOfConsciousness_behavioralTrend}
          data2={graf7.segmentsOfConsciousness_trendOfExperiencing}
          graf_height="500"
          // meaningsText={graf7.segmentsOfConsciousness_meaningsText}
          // number_meaningsText={1}
          // setMeanings={setMeanings7}
          // meanings={meanings7}
          meaning_text={graf7.textSort}
        />
      </div>
      <div className="line">
        <GrafVertical
          id={`${id}_9`}
          // name={name}
          text={graf9.g9_riskPhenomena_text}
          grafTitle={graf9.g9_riskPhenomena_title}
          grafTitle_bottom={[graf9.g9_riskPhenomena_title]}
          data={graf9.g9_riskPhenomena_finalCorrelation}
          graf_height="500"
          meaning_text={graf9.textSort}
          // meaningsText={segmentsOfConsciousness_meaningsText}
          // number_meaningsText={1}
          // setMeanings={setMeanings7}
          // meanings={meanings7}
        />
      </div>
      
    </div>
  );
};

export default GrafData_DataForGraf;
