import { useContext, useState, useEffect } from "react";
import "./UsersData.scss";
import Search from "../../../components/elements/Search/Search";
import { GlobalContext } from "../../../global/GlobalContext";
import route_findUserAndColorSet from "../../../routes/global/route_post_universal";
import { useParams } from 'react-router-dom';

import UsersDataResult from "../../../components/pages/UsersData/UsersData_Result/UsersData_Result";
// import UsersDataResult from "../../../components/pages/UsersData/UsersData_Result/UsersDataResult";

const UsersData = () => {
  const { userID } = useParams();
  const [URLValue, setURLValue] = useState("")

  const { urlServer} = useContext(GlobalContext);
  const [userName, setUserName] = useState("");
  const [fetch_result, setFetch_result] = useState([]);

  // -------------------------------
  // hooks

  // - set userID from URL
  useEffect(() => {
    if (userID && !userName) {
      setURLValue(userID); // nastavíme ID do vyhledávacího inputu
    }
  }, [userID, userName]);

  // ---------------------------------------

  return (
    <div className="UsersData">
      <div className="UserData_main">
        <section className="title">
          <h1>Uživatelská data</h1>
        </section>

        <section className="search">
          <Search
            inputType="text"
            inputLabel="Zadejte jméno respondenta"
            inputID="userName"
            inputRegex="ID"
            state={userName || URLValue}
            setState={(value) => {
              setUserName(value); // Nastavíme userName
              if (value === "") setURLValue(""); // Pokud je input prázdný, smažeme URLValue
            }}
            wordResultKey={userName ? "name" :"userID"}
            urlFetch="/findUserName"
            route={route_findUserAndColorSet}
            setFetch_result={setFetch_result}
          />
        </section>

        <section className="result">
            {
              fetch_result.length > 0 && 
              fetch_result.map((oneUser) => {
                return <UsersDataResult key={oneUser._id} setUserName={setUserName} oneUser={oneUser} urlServer={urlServer} />
              }) 
            }
          {/* <UsersDataResult fetch_result={fetch_result} setUserName={setUserName} urlServer={urlServer} /> */}
        </section>
      </div>
    </div>
  );
};

export default UsersData;
