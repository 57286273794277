import { useEffect, useContext } from "react";
import InputContainer from "../inputs/InputContainer/InputContainer";
import "./Search.scss";
import route_post_universal from "../../../routes/global/route_post_universal";
import { GlobalContext } from "../../../global/GlobalContext";

const Search = ({
  inputType,
  inputLabel,
  inputID,
  inputRegex,
  state,
  setState,
  // urlServer,
  wordResultKey,
  urlFetch,
  // fetch_result,
  setFetch_result,
}) => {
    const { urlServer, setError } = useContext(GlobalContext);
    // Search
    useEffect(() => {
        const f_fetchData = async () => {
            // setFetch_result([]); // správná funkce pro nastavení stavu
            if (state.length >= 2) {
              const result = await route_post_universal(urlServer, urlFetch, wordResultKey, state)
              if (result.fetch_data) {
                setFetch_result(result.fetch_data); // správná funkce pro nastavení stavu
              } else {
                setFetch_result([]);
                setError(result.msg);
              }
            }
          };
      
          if(urlFetch !== "" && state.length >= 2) {
            f_fetchData();
          } 
          // else if (fetch_result) {
          //     setFetch_result([]); // správná funkce pro nastavení stavu
          // }
          
      
          // eslint-disable-next-line react-hooks/exhaustive-deps
    },[state])

  return (
    <div className="Search">
      <div className="Search_box">
        <InputContainer
          type={inputType}
          label={inputLabel}
          id={inputID}
          regexSetting={inputRegex}
          value={state}
          set={setState}
          autocomplete="off"
        />
      </div>
    </div>
  );
};

export default Search;