import { useContext } from "react";
import { GlobalContext } from "../../../../global/GlobalContext";
import route_login from "../../../../routes/pages/login/route_login";
import localStorage_set from "../../../../utils/localStorage/localStorage_set";

const BtnLogin = ({ loginEmail, loginPassword, setErrorMSG }) => {
    const { urlServer, setAdmin, setToken_active, setActive_time, setError } = useContext(GlobalContext);

    const btnLogin = async (e) => {
        e.preventDefault();
    
        if (loginEmail.length < 5 || loginEmail.length > 254) {
          setErrorMSG("E-mail musí mít mezi 5 a 254 znaky.");
          return;
      }else if (loginPassword.length < 8 || loginPassword.length > 64) {
          setErrorMSG("Heslo musí mít mezi 8 a 64 znaky.");
          return;
      } else {
        const emailLowerCase = loginEmail.toLowerCase();
        const response = await route_login(
          urlServer,
          "/loginAdmin",
          "email",
          emailLowerCase,
          "password",
          loginPassword
        );
    
        // save Admin Data + active time - LS + GC
        if(response.admin_name){
            // Token active
            let localStorage_object = {
              token_active: true
            }
            localStorage_set(localStorage_object, "token_active")
            setToken_active(true);

            // active_time
            localStorage_object = {
              active_time: response.active_time
            }
            localStorage_set(localStorage_object, "active_time")
            setActive_time(response.active_time)
            
 
            localStorage_object = {
              admin_name: response.admin_name,
              admin_surname: response.admin_surname,
              admin_phone: response.admin_phone,
              admin_gender: response.admin_gender,
              admin_email: response.admin_email,
              admin_rank: response.admin_rank,
              admin_color: response.admin_color,
              admin_position_name: response.position_name,
              admin_company_Name: response.companyName.company
            };
            localStorage_set(localStorage_object, "admin")
            setAdmin(response.admin_name, response.admin_surname, response.admin_phone, response.admin_gender, response.admin_email, response.admin_rank, response.admin_color, response.position_name, response.companyName.company)
            
            setError(`Vítejte ${response.admin_gender === 0 ? "pane" : "paní"} ${response.admin_addressing_surname || response.admin_surname}`)
        }
        
        setErrorMSG(response.msg);
      }
      };

  return (
    <>
      <button type="submit" onClick={btnLogin}>Přihlásit</button>
    </>
  );
};

export default BtnLogin;
